.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Notifications style */
.Toastify__toast--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;  
}
.Toastify__toast--success > button {
  color: #155724;  
}

.Toastify__toast--info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;  
}
.Toastify__toast--info > button {
  color: #004085;  
}

.Toastify__toast--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.Toastify__toast--warning > button {
  color: #856404;  
}

.Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.Toastify__toast--error > button {
  color: #721c24;  
}

.Toastify__toast {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.Toastify__toast-body {
  margin:0;
}

.Toastify__toast{
  min-height: 50px;
}


::-webkit-scrollbar-thumb { background: rgb(174, 174, 174); border: 5px solid rgb(255, 255, 255); border-radius: 12px; }
::-webkit-scrollbar { width: 17px; height: 17px; border-radius: 8px; }

.custom-control-label:before{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 100px;
  background-color:white;
}

.custom-container {
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #64B867;
  border-color: #64B867;
} 
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 208, 114, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(111, 208, 114, 0.25);
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: white;
  border-color: #64B867;
}    
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #64B867;
  position: absolute;
  top: 0.42rem;
  left: -1.26rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkbox {
  margin-right: 5px;
}

.checkbox-group {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.checkbox-label {
  margin-bottom: 0px;
}

.form-group {
  padding-top: 10px;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: #F0F5F8;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

#content {
width: 100%;
}
/* @media (max-width: 768px) {
  #sidebar {
      margin-left: -250px;
  }
  #sidebar.active {
      margin-left: 0;
  }
} */


.fill-green path {
  fill:#64B867;
}

.fill-red path {
  fill: #E56E6E;
}

.fill-white path {
  fill:#FFF;
}

.cursor-pointer {
  cursor: pointer;
}

.titleText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #454A58;
}

.headerText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #69719B;
}

.contentText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #535B7C;
}
