@import url(https://fonts.googleapis.com/css?family=Poppins);
// Override default variables before the import
$body-bg: #fff;
$font-family-base: Poppins;
//$border-radius: 0px;
//$btn-border-radius: 0px;
$green: #64B867;
$blue: #007bff;
$link-color: $green;
$link-hover-decoration: none;
$nav-pills-link-active-bg: $green;
//$theme-colors: (primary: #64b867, secondary: #535B7C);
//$modal-header-padding: 10px;
$font-size-base: 0.9rem;
$close-font-size: $font-size-base * 1.9;
//$input-focus-border-color: lighten($blue, 25%);
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';